export enum LocalStorageKeys {
  WRH_CACHE_TOKEN = 'WRH_CACHE_TOKEN',
  WRH_CACHE_USER = 'WRH_CACHE_USER',
  WRH_CACHE_KEEP_CONNECTED = 'WRH_CACHE_KEEP_CONNECTED',
  WRH_CACHE_REFRESH_TOKEN = 'WRH_CACHE_REFRESH_TOKEN',
  WRH_CACHE_GROUPS = 'WRH_CACHE_GROUPS',
  WRH_CACHE_AUTHORITIES = "WRH_CACHE_AUTHORITIES",
  WRH_CACHE_PROFILES = "WRH_CACHE_PROFILES",
  WRH_CACHE_PROFILE_SELECTED = "WRH_CACHE_PROFILE_SELECTED",
  WRH_CACHE_CLIENT_ID = "WRH_CACHE_CLIENT_ID",
  WRH_CACHE_PERSONA_ID = "WRH_CACHE_PERSONA_ID",
  WRH_CACHE_PROFILE_ID = "WRH_CACHE_PROFILE_ID"
}
