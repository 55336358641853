<mat-form-field
  appearance="outline"
  class="mat-input"
  [class.mat-form-field-invalid]="required && formInvalid"
  (click)="$event.stopPropagation()"
>
  <mat-label>{{ label }}{{ required ? " *" : "" }}</mat-label>
  <input
    pInputText
    matInput
    type="text"
    [formControl]="dateTimeControl"
    mask="00/00/0000 00:00"
    (click)="$event.stopPropagation()"
  />
  <div class="d-flex" matSuffix>
    <button
      class="btn-icon"
      (click)="$event.stopPropagation(); openCalendar()"
      [disabled]="disabled"
    >
      <mat-icon class="icon-search">calendar_month</mat-icon>
    </button>
  </div>
</mat-form-field>

<ngx-mat-timepicker
  #timepicker
  [enableKeyboardInput]="true"
  [format]="24"
  [editableHintTmpl]="hint"
  [timepickerClass]="'custom-picker'"
  [defaultTime]="selectedTime ?? '00:00'"
  [confirmBtnTmpl]="buttonConfirm"
  [cancelBtnTmpl]="buttonCancel"
  (timeSet)="onTimeChange($event); onConfirm()"
></ngx-mat-timepicker>

<ng-template #hint>
  <style>
    span {
      font-size: 13px;
    }
  </style>
  <span>Utilize as setas do teclado para selecionar</span>
</ng-template>

<ng-template #buttonConfirm>
  <button
    mat-button
    class="btn text-button subtitle subtitle-1 semibold primary-color"
  >
    Confirmar
  </button>
</ng-template>

<ng-template #buttonCancel>
  <button
    mat-button
    class="btn text-button subtitle subtitle-1 semibold primary-color"
  >
    Cancelar
  </button>
</ng-template>
