import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { defineLocale, ptBrLocale } from 'ngx-bootstrap/chronos';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
defineLocale('pt-br', ptBrLocale);

@Component({
  selector: 'app-input-data-hour-picker-dialog',
  templateUrl: './input-data-hour-picker-dialog.component.html',
  styleUrls: ['./input-data-hour-picker-dialog.component.scss'],
})
export class InputDataHourPickerDialogComponent implements OnInit {
  selectedDate?: Date;

  constructor(
    public dialogRef: MatDialogRef<InputDataHourPickerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private localeService: BsLocaleService
  ) {
    this.selectedDate = data.selectedDate;
    this.localeService.use('pt-br');
  }

  ngOnInit(): void {}

  onContinue() {
    this.dialogRef.close({ date: this.selectedDate, next: true });
  }
}
