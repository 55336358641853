<mat-dialog-content>
  <bs-datepicker-inline [(bsValue)]="selectedDate"></bs-datepicker-inline>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="row" style="width: 100%">
    <div class="col-4"></div>
    <button
      mat-button
      mat-dialog-close
      class="btn text-button subtitle subtitle-1 semibold primary-color col-4"
    >
      Cancelar
    </button>
    <button
      mat-button
      mat-dialog-close
      cdkFocusInitial
      class="btn text-button subtitle subtitle-1 semibold primary-color col-4"
      (click)="onContinue()"
    >
      Confirmar
    </button>
  </div>
</mat-dialog-actions>
