import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { isValid, parse } from 'date-fns';
import { NgxMatTimepickerComponent } from 'ngx-mat-timepicker';
import { PrimeNGConfig } from 'primeng/api';
import { ptBrCalendar } from '../../utils/global-variables';
import { InputDataHourPickerDialogComponent } from './input-data-hour-picker-dialog/input-data-hour-picker-dialog.component';

@Component({
  selector: 'app-input-data-hour-picker',
  templateUrl: './input-data-hour-picker.component.html',
  styleUrls: ['./input-data-hour-picker.component.scss'],
})
export class InputDataHourPickerComponent {
  @Input() initialDate?: Date | string = '';
  @Input() label: string = '';
  @Input() required: boolean = false;
  @Input() formInvalid: boolean = false;
  @Input() isOpenInModal: boolean = false;
  @Input() disabled: boolean = false;
  @Output() dateSelected: EventEmitter<string> = new EventEmitter<string>();

  selectedDate?: Date = new Date();
  selectedTime?: string = '00:00';
  selectedDateTime?: Date;
  dateTimeControl: FormControl = new FormControl('');

  ptBrCalendar = ptBrCalendar;

  @ViewChild('timepicker')
  timepicker?: NgxMatTimepickerComponent;

  constructor(
    private config: PrimeNGConfig,
    private eRef: ElementRef,
    private dialog: MatDialog
  ) {
    this.config.setTranslation(ptBrCalendar);

    this.dateTimeControl.valueChanges.subscribe((value) => {
      if (value.length >= 12) {
        const parsedDate = parse(value, 'ddMMyyyyHHmm', new Date());
        if (isValid(parsedDate)) {
          this.selectedDate = new Date(parsedDate.toLocaleDateString('en-US'));
          this.selectedTime = parsedDate
            .toLocaleTimeString('sv')
            .substring(0, 5);
          this._updateInputValue();
          this.dateSelected.emit(
            this.selectedDateTime
              ? this.formatDataISO(this.selectedDateTime)
              : ''
          );
        } else {
          this.selectedDate = undefined;
          this.selectedTime = '00:00';
          this.selectedDateTime = undefined;
          this.dateTimeControl.setValue('', { emitEvent: false });
        }
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['initialDate']) {
      if (this.initialDate) {
        const newDate = new Date(this.initialDate);
        this.selectedDate = new Date(newDate.toLocaleDateString('en-US'));
        this.selectedTime = newDate.toLocaleTimeString('sv').substring(0, 5);
      } else {
        this.selectedDate = undefined;
        this.selectedTime = '00:00';
      }
      this._updateInputValue();
    }

    if (changes['disabled']) {
      if (this.disabled) {
        this.dateTimeControl.disable({ emitEvent: false });
      } else {
        this.dateTimeControl.enable({ emitEvent: false });
      }
    }
  }

  openCalendar() {
    const dialogRef = this.dialog?.open(InputDataHourPickerDialogComponent, {
      width: '370px',
      data: {
        selectedDate: this.selectedDate,
      },
    });

    dialogRef?.afterClosed().subscribe((value) => {
      if (value) {
        this.selectedDate = value.date;
        if (value.next) {
          this.timepicker?.open();
        }
      }
    });
  }

  formatDate(date: Date): string {
    return date.toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
  }

  formatDataISO(data: Date): string {
    const pad = (num: number) => (num < 10 ? '0' + num : num);

    const ano = data.getFullYear();
    const mes = pad(data.getMonth() + 1);
    const dia = pad(data.getDate());
    const hora = pad(data.getHours());
    const minuto = pad(data.getMinutes());
    const segundo = pad(data.getSeconds());

    return `${ano}-${mes}-${dia}T${hora}:${minuto}:${segundo}`;
  }

  onTimeChange(time: string) {
    this.selectedTime = time;
  }

  onConfirm() {
    this._updateInputValue();
    this.dateSelected.emit(
      this.selectedDateTime ? this.formatDataISO(this.selectedDateTime) : ''
    );
  }

  private _updateInputValue() {
    this.selectedDateTime = undefined;
    if (this.selectedDate && this.selectedTime) {
      this.selectedDateTime = new Date(
        this.selectedDate.toLocaleDateString('en-US')
      );
      const times = this.selectedTime.split(':');
      this.selectedDateTime.setHours(parseInt(times![0]), parseInt(times![1]));
    }
    this.dateTimeControl.setValue(
      this.selectedDateTime ? this.formatDate(this.selectedDateTime) : '',
      { emitEvent: false }
    );
  }
}
