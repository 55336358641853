import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NavigationExtras, Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { Subject, debounceTime, distinctUntilChanged, from, takeUntil } from 'rxjs';
import { SearchColumnConfiguration } from 'src/app/shared/components/search/interfaces/search-table-column-configuration.interface';
import { SearchTableDeleteDialogConfiguration, SituationDialogConfiguration } from 'src/app/shared/components/search/interfaces/search-table-delete-dialog-configuration.interface';
import { SearchTableSelectConfiguration } from 'src/app/shared/components/search/interfaces/search-table-select-configuration.interface';
import { ToastComponent } from 'src/app/shared/components/toaster/toast/toast.component';
import { ProfilePermissions } from 'src/app/shared/constants/profile-permissions';
import { ClientDTO } from 'src/app/shared/domains/clientDto';
import { ResumedUserClient, UserClientPage } from 'src/app/shared/domains/user-client/resumed-user-client';
import { ClientService } from 'src/app/shared/services/client.service';
import { UserClientService } from 'src/app/shared/services/user-client.service';
import { GlobalFunctions } from 'src/app/shared/utils/global-functions';
import { masks } from 'src/app/shared/utils/global-variables';
import { UserClient } from './../../../shared/domains/user-client/user-client';
import { MenuOptionsModel } from 'src/app/shared/components/menu-options/models/menu-options.model';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-user-client-search',
  templateUrl: './user-client-search.component.html',
  styleUrls: ['./user-client-search.component.scss'],
})
export class UserClientSearchComponent implements OnInit, OnDestroy {
  @ViewChild('userClientTableSort') userTableSort!: MatSort;
  @ViewChild('confirmModal') confirmModal: ConfirmModalComponent | undefined;

  showConfirmModal: boolean = false;
  onDestroy$ = new Subject<void>();
  clientSelectedFormControl = new FormControl();
  selectedClient = {} as ClientDTO;
  activeClients: ClientDTO[] = [];
  userSelectedFormControl = new FormControl();
  lastFilteredValue = '';
  userFilteredByClient: UserClient[] = [];
  allUsers: ResumedUserClient[] = [];
  paginationCard: number = 10;
  canCreate = false;
  canChangeSituation: boolean = true;
  canDelete: boolean = true;
  canResetPass: boolean = true;
  canUpdate: boolean = true;
  dataSource = new MatTableDataSource<any>();
  searchFormControl = new FormControl();
  paginator: MatPaginator | undefined;
  totalElements!: number;
  pageLength!: number;
  pageIndex!: number;
  originalData: any[] = [];
  isSortingApplied: boolean = false;
  displayedColumns: SearchColumnConfiguration[] = [];
  menuOptions: MenuOptionsModel[] = [];
  showUserGroupsModal: boolean = false;
  selectedUserControl = new FormControl();

  selectClient: SearchTableSelectConfiguration = {
    labelValueName: "tradingName",
    data: [],
    placeholder: 'Cliente',
    disableAddButtonWhileNotSelect: true,
  }

  changeSituationDialogInfo: SituationDialogConfiguration = {
    description: '',
    onConfirmFn: this.changeActiveUser.bind(this),
    title: 'Deseja mesmo ',
    title2: ' este usuário?',
    situationFieldName: 'isActive'
  }

  dialogInfo: SearchTableDeleteDialogConfiguration = {
    onConfirmFn: this.deleteUserClient.bind(this),
    title: 'Deseja mesmo excluir este usuário?',
    description: 'Caso confirme, essa ação não poderá ser desfeita.'
  };

  constructor(
    private router: Router,
    private userClientService: UserClientService,
    private clientService: ClientService,
    private permissionsService: NgxPermissionsService,
    public globalFunctions: GlobalFunctions,
    private toastComponent: ToastComponent,
    private ref: ChangeDetectorRef
  ) { }

  initMenuOptions() {
    this.menuOptions = [{
      menuAction: () => { this.enableAll() },
      menuIcon: 'toggle_on',
      menuTitle: 'Ativar',
      hasPermission: this.canChangeSituation
    },
    {
      menuAction: () => { this.disableAll() },
      menuIcon: 'toggle_off',
      menuTitle: 'Inativar',
      hasPermission: this.canChangeSituation
    },
    {
      menuAction: () => { this.resetPassWord() },
      menuIcon: 'key',
      menuTitle: 'Resetar senha',
      hasPermission: this.canResetPass
    },
    // {
    //   menuAction: () => { this.deleteAll() },
    //   menuIcon: 'delete',
    //   menuTitle: 'Excluir',
    //   hasPermission: this.canDelete
    // }
  ]
  }

  initColumns() {
    this.displayedColumns = [
      {
        columnName: '',
        valueName: 'checkbox',
        columnValueCssClass: 'column-10',
        isCheckbox: true
      },
      {
        columnName: 'Nome',
        valueName: 'name',
        columnValueCssClass: 'column-30 column-category-20 truncate-words',
      },
      {
        columnName: 'CPF',
        valueName: 'cpf',
        mask: masks.cpfMask,
        columnValueCssClass: 'column-20 column-category-20 truncate-words',
      },
      {
        columnName: 'E-mail',
        valueName: 'email',
        columnValueCssClass: 'column-30 column-category-20 truncate-words',
      },
      {
        columnName: 'Situação',
        valueName: 'isActive',
        toggleConfiguration: {
          valueName: 'isActive'
        },
        columnValueCssClass: 'column-15 column-category-20 truncate-words',
      },
      {
        actionsConfiguration: {
          useEditButton: this.canUpdate,
          useViewButton: !this.canUpdate,
          extraActions: [
            {
              css: 'margin-right: 1rem',
              iconName: 'group',
              tooltip: 'Visualizar grupos',
              onClick: (value: any) => {
                this.selectedUserControl.setValue({ userId: value.idUser, userClientId: value.id });
                this.showUserGroupsModal = true
              }
            }
          ],
          // useDeleteFeature: true
        },
        columnName: '',
        valueName: 'actions',
        columnValueCssClass: 'p-0 d-flex justify-content-end',
      },
    ];
  }

  async ngOnInit() {
    this.canCreate = await this.permissionsService.hasPermission(
      ProfilePermissions.CREATE_CLIENT_USER
    );
    this.canChangeSituation = await this.permissionsService.hasPermission(
      ProfilePermissions.CHANGE_SITUATION_CLIENT_USER
    );
    this.canDelete = await this.permissionsService.hasPermission(
      ProfilePermissions.DELETE_CLIENT_USER
    );
    this.canResetPass = await this.permissionsService.hasPermission(
      ProfilePermissions.RESET_PASSWORD_CLIENT_USER
    );
    this.canUpdate = await this.permissionsService.hasPermission(
      ProfilePermissions.UPDATE_CLIENT_USER
    );
    this.initColumns();
    this.initMenuOptions();
    this.searchFormControlValueChanges();
  }

  searchFormControlValueChanges(): void {
    this.searchFormControl.valueChanges.pipe(
      debounceTime(600),
      distinctUntilChanged(),
      takeUntil(this.onDestroy$))
      .subscribe(value => {
        if (!value) {
          this.getUserClient(this.selectedClient);
        } else {
          this.getFilteredUserClient([value], this.pageLength, 0);
        }
      })
  }

  async getUserClient(client: any) {
    this.selectedClient.id = client.id;
    this.selectedClient.tradingName = client.name;

    try {
      const data = await this.userClientService.getUserClientPageByClientsIds([client.id]);
      this.afterLoadConfig(data);
    } catch (err) {
      console.error(err);
    }
  }

  async getFilteredUserClient(filter: string[], pageSize: number, page: number) {
    try {
      let params = {
        clientsIds: [this.selectedClient.id],
        filter,
        pageSize,
        page
      };

      const data = await this.userClientService.getFilteredUserClientPageByClientsIds(params);
      this.afterLoadConfig(data);
    } catch (err) {
      console.error(err);
    }
  }

  getUserClientMobileVision(): void {
    this.paginationCard += 10;
    this.getFilteredUserClient([], this.paginationCard, 0);
  }

  searchView(searchText: string) {
    if (!searchText) this.getUserClient(this.selectedClient);
    this.getFilteredUserClient([searchText], this.pageLength, 0);
  }

  afterLoadConfig(data: UserClientPage) {
    this.allUsers = data.content;
    this.dataSource = new MatTableDataSource(data.content);
    this.dataSource.paginator?.firstPage();
    this.pageLength = data.size;
    this.totalElements = data.totalElements;
    this.pageIndex = data.number;

    this.configTable();
  }

  async onPageChange(event: PageEvent) {
    const page = event.pageIndex + 0;
    const pageSize = event.pageSize;
    this.pageLength = event.pageSize;

    if (page == 0) this.pageIndex = 0;

    this.getFilteredUserClient(
      [this.searchFormControl.value ? this.searchFormControl.value : ''],
      pageSize,
      page
    );
  }

  private configTable() {
    if (this.paginator) this.dataSource.paginator = this.paginator;
    if (this.userTableSort) {
      this.dataSource.sort = this.userTableSort;
    }

    this.dataSource.sortingDataAccessor = (item: any, property) => {
      if (property == 'client') {
        return item['client']?.corporateName?.toLowerCase();
      }
      if (property == 'situation') {
        return item['isActive'];
      }
      return item[property]?.toLowerCase();
    };
  }

  applyFilter(searchText: string) {
    this.searchFormControl.setValue(searchText);
  }

  sortingDataAccessorMethod = () => {
    this.isSortingApplied = true;

    this.dataSource = new MatTableDataSource(this.allUsers);
    this.dataSource.sortingDataAccessor = (item, property) => {
      const value = item[property];

      if (!isNaN(value) && typeof value !== 'boolean') {
        return value;
      }

      return String(value).toLowerCase();
    };
  };

  sortingTable = (data: Sort) => {
    if (data.direction !== '') {
      this.sortingDataAccessorMethod();
    } else {
      this.dataSource = new MatTableDataSource(this.allUsers);
    }
  };

  async changeActiveUser(userClient: UserClient) {
    let id = userClient.id ? userClient.id : 0;
    try {
      await this.userClientService.changeSituation(id, userClient.isActive);
      this.toastComponent.showSuccessCustomMessage(
        `Usuário ${userClient.isActive ? 'ativado' : 'inativado'} com sucesso`,
        '',
        3000
      );
    } catch (e) {
      userClient.isActive = !userClient.isActive;
    }
  }

  private async deleteUserClient(userClient: ResumedUserClient) {
    const response = await this.userClientService.delete(userClient.id);

    if (response) {
      this.toastComponent.showSuccessCustomMessage(
        'Sucesso!',
        'Usuário deletado com sucesso!',
        3000
      );

      this.getFilteredUserClient(this.searchFormControl.value ?? '', this.pageLength, this.pageIndex);
    }
  }

  redirectTo(path: string, extras: NavigationExtras = {}) {
    this.router.navigate([path], extras);
  }

  redirectToNewUser() {
    const extras: NavigationExtras = {
      queryParams: { clientId: this.selectedClient.id },
    };
    this.redirectTo('/users-clients/new-user-client', extras);
  }

  redirectToEditUser(userClient: ResumedUserClient) {
    const extras: NavigationExtras = {
      queryParams: { id: userClient.id },
    };
    this.redirectTo('/users-clients/edit-user-client', extras);
  }

  redirectToViewUser(userClient: ResumedUserClient) {
    const extras: NavigationExtras = {
      queryParams: { id: userClient.id },
    };
    this.redirectTo('/users-clients/view-user-client', extras);
  }

  enableAll() {
    this.changeModal(true);
    this.confirmModal?.showModal('Ativar usuários', 'Deseja mesmo ativar os usuários selecionados?')
      .subscribe(async isAccepted => {
        this.changeModal(false);
        if (isAccepted) {
          try {
            const idsList = this.dataSource.data
              .filter(x => x.checkbox === true)
              .map(x => x.id);

            let success: boolean = false;

            for (const id of idsList) {
              success = await this.userClientService.changeSituationByGroupIds([id], true);
              const item = this.dataSource.data.find(x => x.id === id);
              if (item) {
                item.isActive = true;
              }
            }

            if (success) {
              this.toastComponent.showSuccessCustomMessage(`Situação alterada com sucesso`, '', 3000);
              this.getUserClient(this.selectedClient);
            }
          } catch (error: any) {
            if (error.status == 422) {
              this.toastComponent.showWarningCustomMessage('Ops!', error.error.message);
            }
          }
        }
      });
  }

  disableAll() {
    this.changeModal(true);
    this.confirmModal?.showModal('Inativar usuários', 'Deseja mesmo inativar os usuários selecionados?')
      .subscribe(async isAccepted => {
        this.changeModal(false);
        if (isAccepted) {
          try {
            const idsList = this.dataSource.data
              .filter(x => x.checkbox === true)
              .map(x => x.id);

            const success = await this.userClientService.changeSituationByGroupIds(idsList, false);

            if (success) {
              this.toastComponent.showSuccessCustomMessage(`Situação alterada com sucesso`, '', 3000);
              this.getUserClient(this.selectedClient);
            }
          } catch (error: any) {
            if (error.status == 422) {
              this.toastComponent.showWarningCustomMessage('Ops!', error.error.message);
            }
          }
        }
      });
  }

  deleteAll() {
    this.changeModal(true);
    this.confirmModal?.showModal('Deseja mesmo excluir os usuários selecionados?', 'Caso confirme, essa ação não poderá ser desfeita.')
      .subscribe(async isAccepted => {
        this.changeModal(false);
        if (isAccepted) {
          try {
            const idsList = this.dataSource.data
              .filter(x => x.checkbox === true)
              .map(x => x.id);

            const success = await this.userClientService.deleteUserClientByGroupId(idsList);

            if (success) {
              this.toastComponent.showSuccessCustomMessage(`Usuários excluídos com sucesso`, '', 3000);
              this.getUserClient(this.selectedClient);
            }

          } catch (error: any) {
            if (error.status == 422) {
              this.toastComponent.showWarningCustomMessage('Ops!', error.error.message);
            }
          }
        }
      });

  }

  resetPassWord() {
    this.changeModal(true);
    this.confirmModal?.showModal('Resetar senha', 'Deseja mesmo resetar senha dos usuários selecionados?')
      .subscribe(async isAccepted => {
        this.changeModal(false);
        if (isAccepted) {
          try {
            const ids = this.dataSource.data.filter(x => x.checkbox == true).map(x => {
              return x.idUser;
            });
            const success = await this.userClientService.resetPasswords(ids, this.selectedClient.id ?? 0);
            if (success) {
              this.toastComponent.showSuccessCustomMessage(`Senha resetada com sucesso`, '', 3000);
              this.getUserClient(this.selectedClient);
            }

          } catch (error: any) {
            if (error.status == 422) {
              this.toastComponent.showWarningCustomMessage('Ops!', error.error.message);
            }
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  changeModal(visible: boolean) {
    this.showConfirmModal = visible;
    this.ref.detectChanges();
  }
}
